import { gql } from '@apollo/client'

/**
 * Query for Professional to check the status whether TC is accepted or not
 */
export const GET_ACCEPTED_TC = gql`
  query getAcceptedTermsAndConditions {
    getAcceptedTermsAndConditions {
      accepted
    }
  }
`

// Get organization profile for the specified education period
export const GET_ORG_PROFILE = gql`
  query getOrgProfile($orgId: String!, $educationPeriodCd: String!) {
    getOrgProfile(orgId: $orgId, input: { educationPeriodCd: $educationPeriodCd }) {
      orgId
      educationPeriodCd
      coordinator {
        firstName
        lastName
        title
        email
        phone
      }
      billing {
        firstName
        lastName
        title
        email
        phone
      }
      principal {
        firstName
        lastName
        email
        phone
      }
      alternateContact {
        firstName
        lastName
        title
        email
        phone
      }
      updatedDate
    }
  }
`

// Get organizations data by id
export const GET_ORGS_BY_ID = gql`
  query getOrgs($orgIds: [String!]) {
    getOrgs(input: { orgs: $orgIds }) {
      aiCode
      orgId
      orgName
      highSchoolInd
      districtInd
      adminInd
      nonEduInd
      address {
        streetAddr1
        streetAddr2
        city
        countryCd
        stateCd
        postalCd
        province
      }
    }
  }
`

export const GET_ORG_HIERARCHY = gql`
  query getOrgHierarchy($orgId: String!) {
    getOrgHierarchy(orgId: $orgId) {
      aiCode
      orgId
      orgName
      highSchoolInd
      districtInd
      adminInd
      nonEduInd
    }
  }
`

export const GET_ORG_ADMIN_WINDOWS = gql`
  query getOrgAdminWindows($orgId: String!, $educationPeriodCd: String!) {
    getOrgAdminWindows(orgId: $orgId, input: { educationPeriodCd: $educationPeriodCd }) {
      educationPeriodCd
      adminWindowCd
      adminWindowName
      adminType
      orgType
      adminStartDate
      adminEndDate
      orderDeadlineDate
      voucherExpirationDate
      registrationDeadlineDate
      rosterManagementDeadlineDate
      invoiceDeliveryDate
      allowedExams
    }
  }
`

// Get common data from local cache
export const GET_CACHED_COMMON_DATA = gql`
  query GetCachedCommonData {
    commonData @client {
      currentEduPeriod
      selectedOrg
      selectedSchool
    }
  }
`

// Get order form details from local cache
export const GET_CACHED_ORDER_FORM = gql`
  query GetCachedOrderForm {
    orderForm @client
  }
`

export const GET_ORDERS_BY_ORG_ID = gql`
  query getOrders($orgId: String!, $educationPeriodCd: String!) {
    getOrders(orgId: $orgId, input: { educationPeriodCd: $educationPeriodCd }) {
      orderId
      createdDate
      status
      products {
        code
        expirationDate
        proctorFeeCoveredInd
        quantity
        adminWindowCd
        scoreSendsAllowedInd
        fundingComment
        fundingTypeCd
      }
      purchaseOrderNumber
      firstName
      lastName
      lastUpdatedByUser
      lastUpdatedDate
    }
  }
`

export const GET_VOUCHERS_BY_ORDER_ID = gql`
  query getVouchers($orgId: String!, $orderId: String!) {
    getVouchers(orgId: $orgId, input: { orderId: $orderId }) {
      voucherId
      status
      expirationDate
    }
  }
`

export const GET_VOUCHER_ORDER_UPDATABLE_FIELDS = gql`
  query getVoucherOrderUpdatableFields($orgId: String!, $orderId: String!) {
    getVoucherOrderUpdatableFields(orgId: $orgId, input: { orderId: $orderId }) {
      updatableFields
    }
  }
`

export const GET_ORDER = gql`
  query getOrderById($orgId: String!, $orderId: String!) {
    getOrderById(orgId: $orgId, input: { orderId: $orderId }) {
      orderId
      createdDate
      firstName
      lastName
      lastUpdatedByUser
      lastUpdatedDate
      purchaseOrderNumber
      status
      products {
        code
        adminWindowCd
        expirationDate
        fundingComment
        fundingTypeCd
        proctorFeeCoveredInd
        quantity
        scoreSendsAllowedInd
      }
      paymentUrl
    }
  }
`

// returns scores in OFFICIAL status
export const GET_SCORES = gql`
  query getScoresExtended($orgId: String!, $educationPeriodCd: String!) {
    getScoresExtended(orgId: $orgId, input: { educationPeriodCd: $educationPeriodCd }) {
      registrationId
      catapultId
      score
      productCode
      adminDate
      educationLevelCd
      attendingOrgName
      firstName
      lastName
      middleInitial
      dateOfBirth
    }
  }
`

export const GET_SCORE_DETAILS = gql`
  query getScoreDetails($orgId: String!, $catapultId: String!, $registrationId: String!) {
    getScoreDetails(orgId: $orgId, input: { catapultId: $catapultId, registrationId: $registrationId }) {
      catapultId
      middleInitial
      preferredName
      genderCd
      races
      ethnicities
      attendingAiCode
      registrationId
      remoteProctoringInd
      retakeInd
      voucherId
      voucherOrgId
      voucherOrderId
    }
  }
`

export const GENERATE_SCORE_REPORT_EXPORT = gql`
  query generateScoreReportExport($orgId: String!, $educationPeriodCd: String!) {
    generateScoreReportExport(orgId: $orgId, input: { educationPeriodCd: $educationPeriodCd }) {
      requestId
    }
  }
`
