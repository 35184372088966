import classnames from 'classnames'
import { deleteUserHistory } from '@/utils/user'

const getLoginLink = (idp, addlParams = []) =>
  `https://account.collegeboard.org/login/login?appId=${Config.CLEP_APP_ID}&idp=${idp}&${addlParams.join('&')}&DURL=${
    window.location.origin
  }/dashboard`

const LINK_ACCOUNT = `https://account.collegeboard.org/login/signUp?appId=${Config.CLEP_APP_ID}`
const CLASSES_BTN = `cb-btn cb-btn-lg cb-btn-primary display-block`

const Login = () => {
  useEffect(() => {
    // clear any cached routes
    deleteUserHistory()
  }, [])

  const renderLoginBox = () => (
    <div className="cb-card">
      <div className="cb-margin-24 cb-margin-xs-4 cb-align-center">
        <h1 className="cb-h3">Sign In to My CLEP</h1>
        <p className="cb-margin-top-16 cb-margin-bottom-32 cb-font-weight-light">
          Which type of account do you want to <strong className="cb-font-weight-medium">sign in</strong> to?
        </p>
        <a href={getLoginLink('ECL')} className={CLASSES_BTN}>
          Student
        </a>
        <a href={getLoginLink('EPL', ['skipEnroll=Y'])} className={classnames(CLASSES_BTN, 'cb-margin-top-24')}>
          Professional
        </a>
        <div className="cb-margin-top-48 cb-padding-top-32 cb-border-top-1 cb-border-dashed cb-border-color">
          <p className="cb-font-weight-light cb-margin-bottom-16">Don&#39;t have an account?</p>
          <a href={LINK_ACCOUNT} className="cb-link-black cb-font-weight-medium">
            Create Account
          </a>
        </div>
      </div>
    </div>
  )

  const renderInfoBox = () => (
    <div className="cb-border cb-white-bg">
      <div className="cb-padding-24 cb-padding-md-up-32">
        <h2 className="cb-h4 cb-margin-bottom-8">Students</h2>
        <p className="cb-margin-bottom-32">
          {`Register for CLEP exams, order study materials, view CLEP scores, and send scores to colleges.`}
        </p>
        <h2 className="cb-h4 cb-margin-bottom-8">Professionals</h2>
        <p className="cb-margin-bottom-32">
          {`Order CLEP exam vouchers for your students. Schools and districts can also access CLEP scores.`}
        </p>
      </div>
    </div>
  )

  return (
    <>
      <div role="main" id="main-content" tabIndex={-1}>
        <div className="cb-tint-blue5-2 cb-footer-reset cb-padding-top-72">
          <div className="container">
            <div className="cb-padding-md-up-left-72 cb-padding-md-up-right-72">
              <div className="row">
                <div className="col-md-5">{renderLoginBox()}</div>
                <div className="col-md-7">
                  <div className="cb-margin-md-down-top-48 cb-margin-md-up-left-72">{renderInfoBox()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
